import Vue from "vue";

export default {
    namespaced: true,
    state: {
        characters: [],
        skills: [],
    },
    getters: {
        characters: (state) => state.characters,
        mainCharacter: (state) => state.characters.filter(c => c.valid)[0] ?? {},
        skills: (state) => state.skills,
    },
    mutations: {
        setCharacters: (state, characters) => state.characters = characters,
        setSkills: (state, skills) => state.skills = skills,
    },
    actions: {
        getCharacters: (context) => {
            return Vue.axios.get('/characters')
                .then((response) => {
                    return context.commit('setCharacters', response['hydra:member']);
                });
        },
        addCharacter: () => {
            location.href = process.env.VUE_APP_BACKEND + '/oauth';
        },
        deleteCharacter: (context, character) => {
            return Vue.axios.delete('/characters/' + character.esiId);
        },
        getCharacterDetails: (context, id) => {
            return Vue.axios.get('/characters/' + id);
        },
        getSkills: (context) => {
            return Vue.axios.get('/skills')
                .then((response) => {
                    return context.commit('setSkills', response['hydra:member']);
                });
        },
        getCharacterWallet: (context, id) => {
            return Vue.axios.get('/characters/' + id + '/wallet');
        },
        getCharacterSkills: (context, id) => {
            return Vue.axios.get('/characters/' + id + '/skills')
                .then((response) => response['hydra:member']);
        },
    },
}