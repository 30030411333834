export default [
    {
        path: '/admin',
        component: () => import('../layouts/AdminLayout.vue'),
        children: [
            {
                path: 'home',
                name: 'AdminHome',
                component: () => import('../views/admin/AdminHomeView.vue'),
            },
            {
                path: 'users',
                name: 'UserManagement',
                component: () => import('../views/admin/UserManagementView.vue'),
            },
        ].map(r => {
            return {
                ...r,
                meta: {
                    auth: true,
                    admin: true,
                },
            };
        }),
        redirect: { path: '/admin/home'},
    },
]