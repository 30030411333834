export default [
    {
        path: '/',
        component: () => import('../layouts/ProtectedLayout.vue'),
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '../views/protected/HomeView.vue'),
            },
            {
                path: 'character/:id',
                name: 'Character',
                component: () => import(/* webpackChunkName: "character" */ '../views/protected/CharacterView.vue'),
                children: [
                    {
                        path: 'wallet',
                        name: 'Wallet',
                        component: () => import(/* webpackChunkName: "wallet" */ '../views/protected/character/WalletView.vue'),
                    },
                    {
                        path: 'skills',
                        name: 'Skills',
                        component: () => import(/* webpackChunkName: "skills" */ '../views/protected/character/SkillsView.vue'),
                    },
                ],
                redirect: { path: 'character/:id/wallet' },
            },
            {
                path: 'settings',
                name: 'Settings',
                component: () => import(/* webpackChunkName: "settings" */ '../views/protected/SettingsView.vue'),
                children: [
                    {
                        path: 'characters',
                        name: 'Characters',
                        component: () => import(/* webpackChunkName: "characters" */ '../views/protected/settings/CharactersView.vue'),
                    },
                    {
                        path: 'user',
                        name: 'User',
                        component: () => import(/* webpackChunkName: "characters" */ '../views/protected/settings/UserView.vue'),
                    },
                ],
                redirect: { path: 'settings/characters' },
            },
        ].map(r => {
            return {
                ...r,
                meta: {
                    auth: true,
                    admin: false,
                },
            };
        }),
        redirect: { path: '/home'},
    },
]