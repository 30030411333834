import Vue from 'vue'
import './plugins/axios'
import './plugins/vuemeta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from "moment"

Vue.config.productionTip = false

Vue.filter('date', function (value) {
  return moment(value).format('DD.MM.YYYY');
})

Vue.filter('dateTime', function (value) {
  return moment(value).format('DD.MM.YYYY - hh:mm');
})

Vue.filter('currency', function (value) {
  if (!value) return '0 ISK';

  return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'ISK'}).format(value);
})

Vue.filter('characterImage', function (value) {
  return 'https://images.evetech.net/characters/' + value + '/portrait?size=256';
})
Vue.filter('corporationImage', function (value) {
  return 'https://images.evetech.net/corporations/' + value + '/logo?size=64';
})
Vue.filter('allianceImage', function (value) {
  return 'https://images.evetech.net/alliances/' + value + '/logo?size=64';
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
