import Vue from "vue";

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        isLoggedIn: (state) => state.user !== null,
        isAdmin: (state) => state.user?.admin ?? null,
        username: (state) => state.user?.username ?? null,
        id: (state) => state.user?.id ?? null,
    },
    mutations: {
        setUser: (state, user) => state.user = user,
        unsetUser: (state) => state.user = null,
    },
    actions: {
        login: (context, user) => {
            return Vue.axios.post('/login', user)
                .then((response) => {
                    return context.commit('setUser', response);
                });
        },
        logout: (context) => {
            return Vue.axios.get('/logout')
                .then(() => context.commit('unsetUser'));
        },
    },
}