<template>
    <router-view class="app"/>
</template>

<script>
export default {
    name: 'App',
    metaInfo: {
        titleTemplate: 'Mero Systems | %s',
    },
};
</script>

<style>
html, .app {
    background-image: url('@/assets/background.webp') !important;
    background-size: cover !important;
    background-attachment: fixed !important;
}
</style>