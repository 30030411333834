import Vue from 'vue'
import Vuex from 'vuex'
import Security from "@/store/Security"
import createPersistedState from 'vuex-persistedstate'
import Characters from "@/store/Characters";
import User from "@/store/User";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Security: Security,
    Characters: Characters,
    User: User,
  },
  plugins: [
      createPersistedState({
        paths: ['Security']
      })
  ],
})
