import Vue from 'vue'
import VueRouter from 'vue-router'
import Public from "@/router/Public";
import Store from '@/store'
import Protected from "@/router/Protected";
import Admin from "@/router/Admin";

Vue.use(VueRouter)

const routes = [
    ...Public,
    ...Protected,
    ...Admin,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

router.beforeEach((to, from, next) => {
    if (to.name === 'Login' && Store.getters['Security/isLoggedIn']) next('/home');
    if (to.matched.some(t => t.meta.auth) && !Store.getters['Security/isLoggedIn']) next('/login');
    if (to.matched.some(t => t.meta.admin) && !Store.getters['Security/isAdmin']) next('/home');

    if (!to.matched.length) next('/home');

    next();
})
