import Vue from "vue";

export default {
    namespaced: true,
    actions: {
        getUsers: (context, {itemsPerPage, page}) => {
            return Vue.axios.get('/users'
                + '?itemsPerPage=' + itemsPerPage
                + '&page=' + page)
                .then((response) => {
                    return {
                        total: response['hydra:totalItems'],
                        items: response['hydra:member'],
                    };
                });
        },
        changePassword: (context, newPassword) => {
            return Vue.axios.put('/users/' + context.rootGetters['Security/id'], {
                password: newPassword,
            });
        },
        updateUser: (context, updateUser) => {
            return Vue.axios.put('/users/' + updateUser.id, updateUser);
        },
        addUser: (context, newUser) => {
            return Vue.axios.post('/users', newUser);
        },
    },
}