export default [
    {
        path: '/',
        component: () => import('../layouts/PublicLayout.vue'),
        meta: {
            auth: false,
            admin: false,
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "login" */ '../views/public/LoginView.vue'),
            },
        ].map(r => {
            return {
                ...r,
                meta: {
                    auth: false,
                    admin: false,
                },
            };
        }),
        redirect: { path: 'login'},
    },
]